import React, { useEffect } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { images } from '../../../assets'
// import './style.scss'

const {
  flStudioAsioPanel,
  fretboardGui
} = images

const transkrV2UserGuideView = () => {
	window.gtag_sendEvent('user_guide/Transkr_V2')
}

const TranskrV2UserGuide = () => {
  useEffect(() => transkrV2UserGuideView(), [])

  const today = new Date('January 26, 2025')
  
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  const supportUrl = 'mailto:support@algoriffix.com?subject=Transkr%20V2%20Support&body=Please%20describe%20the%20issue%20you%20are%20experiencing...'

  return (
    <div>
    <section id='user-guide'>
      <Container className='user-guide-text'>
        <h2>Transkr V2</h2>
        <p>Last updated: {today.toLocaleDateString("en-SE", options)}</p>
        <p>
          Every instrument has a unique sound, and every player has their own technique to shape that sound. 
          The extraordinary happens when the two elements come together to fuse in an emotional high. 
          The auditory experience of such a sonic entanglement can vary a lot, and this is particularly true for the guitarist. 
          It is therefore practically impossible to collect enough data that is representative of all instruments, all players, 
          all techniques, and all recording environments to train an algorithm that would be able to decipher the message hidden in the music, 
          not to mention the resources such an amount of data would require for storage and processing. 
          To nevertheless provide you with a practical solution, we at Algoriffix have approached the problem from a different angle: 
          We have spent years of research to understand the psychoacoustic phenomena behind pitch perception and its physical correlates 
          and have studied how machine learning algorithms &quot;see&quot; pitch. 
          Based on our findings, we have broken down the problem into its basic elements and 
          developed a tool that gives you the freedom to parametrise a model that works best for you and 
          your instrument &mdash; no cloud, no copyright infringement. This tool is Transkr V2.
        </p>

        <div className='important-box'>
          <p>
            Unless stated otherwise, your instrument should use standard tuning. 
            Making sure it is in tune with the algorithm will give you the best result. 
            Transkr V2 uses the MIDI Tuning Standard with the A above middle C tuned to 440 Hz.
          </p>
        </div>

        <ul className='toc'>
          <li>
            <a href='#usage'>Usage</a>
            <ul>
              <li><a href='#customisation'>Model Customisation and Fine-Tuning</a></li>
              <li><a href='#audio-to-midi'>Audio to MIDI</a></li>
            </ul>
          </li>
          <li><a href="#instruments">Instruments</a></li>
          <li><a href="#fretboard">Fretboard</a></li>
          <li><a href="#licence-key">Licence Key</a></li>
          <li><a href="#version">Version and Ethics Statement</a></li>
          <li><a href="#technical-support">Technical Support</a></li>
        </ul>

        <h3 id='usage'>Usage</h3>
        <p>
          Here you can read up on how to customise and fine-tune Transkr V2 and discover additional use cases.
        </p>

        <h4 id='customisation'>Model Customisation and Fine-Tuning</h4>
        <p>
          It can be a bit tricky to find a model that works well with both single notes and chords, but it is feasible. 
          Should you encounter difficulties finding such a model, you may want to consider creating two different presets: 
          one for chords and one for melody. 
          Electric guitars, e.g., can produce such a thick sound when playing multiple notes at the same time 
          that the algorithm may generate more notes than expected. 
          These extra notes usually represent strong harmonics. 
          It is important to understand that the algorithm tries to explain the sound of your instrument with the model it has, 
          which you can customise and fine-tune. 
          You should start with a default preset for your instrument and then 
          experiment with the parameters that impact the pitch recognition to get a feel for the algorithm. 
          These parameters are:
        </p>
        <ul>
          <li>Gain</li>
          <li>Harmonics</li>
          <li>Bass, mid and treble</li>
          <li>Sensitivity or melody, unison and legato in pro mode</li>
        </ul>
        <dl>
          <dt>Gain</dt>
          <dd>
            &hellip; helps you level the input signal and, by doing so, 
            to control the portion of the signal that undergoes pitch detection. 
            You should avoid saturating the signal by too much.
          </dd>
          <dt>Harmonics</dt>
          <dd>
            &hellip; lets you adjust the harmonic strength of your instrument, 
            which is the main model parameter. 
            Most string instruments are rich in harmonics and 
            the optimum value is usually somewhere between 1.1 and 1.7.
          </dd>
          <dt>Bass, mid and treble</dt>
          <dd>
            &hellip; are the gain parameters of the built-in 3-band equalizer. 
            You should use them to re-shape the tone of your instrument, 
            neutralising harsh or resonant frequencies and/or 
            giving more presence to subtle frequency components.
          </dd>
          <dt>Sensitivity</dt>
          <dd>
            &hellip; helps you fine-tune your custom model in a straightforward way. 
            It is a joint control over the following three parameters, 
            which are accessible after clicking the &quot;pro&quot; button.
          </dd>
          <dt>Melody</dt>
          <dd>
            &hellip; lets you reduce ghost notes. 
            These are undesired pitch artefacts that stem from picking or plucking, fret-hand movement, and wave interference.
          </dd>
          <dt>Unison</dt>
          <dd>
            &hellip; lets you reduce octave doubling. 
            These are undesired pitch artefacts that mostly stem from too strong second harmonics.
          </dd>
          <dt>Legato</dt>
          <dd>
            &hellip; lets you reduce note interruptions. 
            These are undesired amplitude fluctuations that stem from interference effects between notes, including all the harmonics, and 
            resonance between notes and the instrument.
          </dd>
        </dl>
        <p>
          The pitch detection, especially polyphonic, is most sensitive to the instrument model, which includes harmonic strength and tone. 
          Start wiggling with these parameters, setting sensitivity to 1.0, to find a sweet spot. 
          A good custom model should correctly detect the pitch of all open strings separately and the factors of all basic chords. 
          Setting the harmonics parameter to the lowest acceptable value will make sure that notes in the lower register do not absorb notes in the higher register. 
          If there are some ghost notes appearing, do not worry, you can fine-tune later with unison. 
          Increasing the harmonics value can also be an alternative to reducing ghost notes, when playing single notes. 
          If notes in a frequency range are missing, try increasing the equaliser gain for that range. 
          If too many upper harmonics occur, decrease the treble gain, e.g. 
          If the velocity is weak on all notes, increase the gain on your audio interface and/or the gain parameter value. 
          The dynamics parameter affects only the velocity of note-on events, not the instrument model. 
          A value of zero will produce notes with fixed (maximum) velocity.
        </p>

        <h4 id='audio-to-midi'>Audio to MIDI</h4>
        <p>
          Listed below are the use cases for Transkr V2. If you are aware of others, please let us know.
        </p>
        <p>
          <strong>Real-time processing</strong>
        </p>
        <p>
          Once you have set up Transkr V2 in your DAW, you can start transcribing audio to MIDI by a simple click on the green button with the robot head. 
          The button will turn magenta on activation and the clock will start counting. 
          The harmonics parameter is inaccessible during operation, so you will need to click the button again to change it. 
          When active, the algorithm will recognise note patterns based on the custom model and convert them to MIDI events. 
          The plug-in will then pass the MIDI events on to your DAW via the selected MIDI output and channel. 
          In addition, the algorithm tracks
        </p>
        <ul>
          <li>level</li>
          <li>tempo</li>
          <li>key</li>
          <li>notes</li>
          <li>chords</li>
          <li>melody</li>
        </ul>
        <p>
          The level is measured in dB RMS and the tempo in BPM. 
          The key can be major or (natural) minor. 
          Chords are represented by chord symbols and Roman numerals relative to the key, 
          while note names use scientific pitch notation (SPN).
        </p>
        <p id='file-processing'>
          <strong>File processing</strong>
        </p>
        <p>
          You can also convert an existing audio recording of yours to Standard MIDI File (SMF) by means of drag and drop: 
          Simply select a file (WAVE, AIFF or MP3) on your computer, 
          drag it to the Transkr V2 window, and drop it over the green button with the robot head. 
          This will initiate a new conversion process and you will be able to track its progress. 
          The algorithm will resort to the current settings during conversion. 
          On success, the resulting MIDI file will appear in the file manager next to the original audio file. 
          You can expect better results from file forward-backward processing, 
          as opposed to forward-only processing in the real-time case. 
          You can also use the standalone app, which may be quicker, since latency does not matter here. 
          The MIDI file contains the following tracks:
        </p>
        <ul>
          <li>Time signature</li>
          <li>Key signature</li>
          <li>Beats</li>
          <li>Notes</li>
          <li>Chords</li>
          <li>Melody</li>
        </ul>
        <p>
          <strong>Web processing</strong>
        </p>
        <p>
          Although this was not our intention, we would like to mention the possibility to stream audio directly from the web and 
          run it through Transkr V2 or to record it to an audio file and convert the file to MIDI. 
          All you need for this is a virtual audio cable, such as <a href='https://vb-audio.com/Cable/' target='_blank' rel='noreferrer'>VB-CABLE</a>.
        </p>
        <p>
          In FL Studio, you would open the audio settings (F10), select FL Studio ASIO as the audio device and open the ASIO panel. 
          You would then select the output from the virtual audio cable as the input and your default audio interface as the output. 
          Make sure to select the input from the virtual audio cable as the output in your system settings, so audio from the web gets routed to your DAW. 
          The rest is as usual.
        </p>
        <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image className='pb-4' src={flStudioAsioPanel} alt='FL Studio ASIO' fluid />
          </Col>
        </Row>
        <p>
          The chord and melody detection can work magic if the model fits and the modelled instrument dominates the mix. Try it for yourself!
        </p>

        <h3 id='instruments'>Instruments</h3>
        <p>
          Each and every instrument has different characteristics. The following instruments are supported:
        </p>
        <ul>
          <li>Guitar</li>
          <li>Bass</li>
          <li>Voice</li>
          <li>Piano</li>
          <li>Violin family</li>
        </ul>
        
        <h3 id='fretboard'>Fretboard</h3>
        <p>
          The fretboard can be turned on and off when using the guitar as instrument. 
          There is an algorithm in place that will try to find a fingering based on the ringing notes and an estimate of the left-hand position. 
          The fingerings are meant to be &quot;easy&quot; to play. 
          If no fingering can be assigned, the last valid fingering is shown until all notes ring out.
        </p>
        <Row className='justify-content-md-center'>
          <Col lg={10} className='text-center'>
            <Image src={fretboardGui} alt='Fretboard GUI' fluid />
          </Col>
        </Row>
        
        <h3 id='licence-key'>Licence Key</h3>
        <p>
          To unlock the full version, open Transkr V2 and click on the icon in the upper-right corner. 
          Then enter the licence key.
        </p>

        <h3 id='version'>Version and Ethics Statement</h3>
        <p>
          To see the version of Transkr V2, click on the icon in the menu bar or the system tray and select About Transkr V2 from the context menu. 
          Alternatively, select Ethics Statement from the context menu to read where we stand in terms of human artistry and AI.
        </p>
        
        <h3 id='technical-support'>Technical Support</h3>
        <p>
          Having trouble using or tuning Transkr V2? 
          Customers who purchased a licence can contact <a href={supportUrl}>technical support</a>.
        </p>
      </Container>
    </section>
    </div>
  )
}

export default TranskrV2UserGuide
