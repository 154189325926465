import React from 'react'
import { Navigate, Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
// import { Helmet } from 'react-helmet'
// import ReactGA from 'react-ga'
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
// import { googleAnalyticsId, reCaptchaKey } from './constants'
// import App from './App'
import Preloader from './components/Preloader'
// import TimedPopup from './components/HomePageComponents/TimedPopup'
import Promo from './components/HomePageComponents/Promo'
import Banner from './components/HomePageComponents/Banner'
import PitchPerfektFeatures from './components/HomePageComponents/PitchPerfektFeatures'
import SorooshMohassesi from './components/HomePageComponents/SorooshMohassesi'
import PitchPerfektDownload from './components/HomePageComponents/PitchPerfektDownload'
import TranskrV2Features from './components/HomePageComponents/TranskrV2Features'
import ViktorDiaz from './components/HomePageComponents/ViktorDiaz'
import TranskrV2Download from './components/HomePageComponents/TranskrV2Download'
import PricingTable from './components/HomePageComponents/PricingTable'
import Testimonial from './components/HomePageComponents/Testimonial'
import ThankYou from './components/HomePageComponents/ThankYou'
import Footer from './components/HomePageComponents/Footer'
import AboutUs from './components/HomePageComponents/AboutUs'
import Copyright from './components/CopyrightFooter'
import BackToTop from './components/BackToTop'
import UserConsent from './components/UserConsent'
import Privacy from './pages/Privacy'
import Terms from './pages/Terms'
import UserGuide from './pages/UserGuide'
import PitchPerfektUserGuide from './pages/UserGuide/PitchPerfektUserGuide'
import TranskrV2UserGuide from './pages/UserGuide/TranskrV2UserGuide'
import BitwigStudioUserGuide from './pages/UserGuide/BitwigStudioUserGuide'
import FLStudioUserGuide from './pages/UserGuide/FLStudioUserGuide'
import GarageBandUserGuide from './pages/UserGuide/GarageBandUserGuide'
import LogicProUserGuide from './pages/UserGuide/LogicProUserGuide'
import ReaperUserGuide from './pages/UserGuide/ReaperUserGuide'
import CubaseProUserGuide from './pages/UserGuide/CubaseProUserGuide'
import StudioOneProUserGuide from './pages/UserGuide/StudioOneProUserGuide'
import ProToolsUserGuide from './pages/UserGuide/ProToolsUserGuide'
import './index.scss'

// ReactGA.initialize(googleAnalyticsId)

const Root = () => (
	<div className='root-container'>
		<Preloader />
		<UserConsent />
		<Outlet />
		<Copyright />
		<BackToTop />
	</div>
)

const Home = () => (
	<>
		{/* <TimedPopup /> */}
		<Promo />
		<Banner />
		<PitchPerfektFeatures />
		<SorooshMohassesi />
		<PitchPerfektDownload />
		<TranskrV2Features />
		<ViktorDiaz />
		<TranskrV2Download />
		<PricingTable />
		<Testimonial />
		<Footer />
	</>
)

/*
const App = () => (
	<BrowserRouter>
		<Routes>
			<Route path='/'>
				<Route element={<Home />} index />
				<Route path='privacy' element={<PrivacyPolicy />} />
				<Route path='*' element={<Navigate to='/' replace />} />
			</Route>
		</Routes>
	</BrowserRouter>
)
*/

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<Root />}
      errorElement={<Navigate to='/' replace />}
    >
			<Route index element={<Home />} />
			<Route path='guide' element={<UserGuide />} />
			<Route path='guide/pitch-perfekt' element={<PitchPerfektUserGuide />} />
			<Route path='guide/transkr-v2' element={<TranskrV2UserGuide />} />
			<Route path='guide/bitwig-studio' element={<BitwigStudioUserGuide />} />
			<Route path='guide/cubase-pro' element={<CubaseProUserGuide />} />
			<Route path='guide/fl-studio' element={<FLStudioUserGuide />} />
			<Route path='guide/garageband' element={<GarageBandUserGuide />} />
			<Route path='guide/logic-pro' element={<LogicProUserGuide />} />
			<Route path='guide/pro-tools' element={<ProToolsUserGuide />} />
			<Route path='guide/reaper' element={<ReaperUserGuide />} />
			<Route path='guide/studio-one-pro' element={<StudioOneProUserGuide />} />
			<Route path='about_us' element={<AboutUs />} />
			<Route path='privacy' element={<Privacy />} />
			<Route path='terms' element={<Terms />} />
			<Route
				path='thank_you/transkrv2'
				element={<ThankYou product='Transkr V2' />}
			/>
    </Route>
  ), {
		future: {
			v7_relativeSplatPath: true
		}
	}
)

const App = () => (
	<RouterProvider
		router={router}
		future={{
    	v7_startTransition: true
  	}}
	/>
)

createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)

/*
	<Helmet>
		<meta property='og:url' content={window.location.href} />
		<meta property='og:type' content='website' />
		<meta property='og:title' content='AI As Your Co-Writer, Tutor and Jam Mate | Algoriffix' />
		<meta
			property='og:description'
			content={`Use your instrument or voice as a MIDI controller and get AI co-written accompaniment in return.`}
		/>
		<meta property='og:image' content='%PUBLIC_URL%/share_icon.png' />
	</Helmet>
	<GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
		<Helmet>
			<meta property='og:url' content={window.location.href} />
			<meta property='og:type' content='website' />
			<meta property='og:title' content='AI As Your Co-Writer, Tutor and Jam Mate | Algoriffix' />
			<meta
				property='og:description'
				content={`Record musical sketches on the guitar, use your voice as a MIDI controller,
					or upload a melody and get AI co-written accompaniment in return.`}
			/>
			<meta property='og:image' content='%PUBLIC_URL%/share_icon.png' />
		</Helmet>
		<App />
	</GoogleReCaptchaProvider>,
*/

export default App
