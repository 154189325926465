import React, { useEffect } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { images } from '../../../assets'
// import './style.scss'

const {
  studioOneProAudioSetup,
  studioOneProExternalDevices,
  studioOneProAudioTrack,
  studioOneProInstrument,
  studioOneProTranskrV2
} = images

const studioOneProUserGuideView = () => {
	window.gtag_sendEvent('user_guide/Studio_One_Pro')
}

const StudioOneProUserGuide = () => {
  useEffect(() => studioOneProUserGuideView(), [])

  return (
    <section id='user-guide'>
      <Container className='user-guide-text'>
        <h2>Studio One Pro</h2>
        <p>
          The screenshots were taken on a Windows PC, yet the steps are essentially the same on a Mac.
        </p>
        
        <p>
          <strong>Step 1</strong>
        </p>
        <p>
          Open the audio setup in options and select your audio interface as the audio device. 
          We recommend using the drivers from the manufacturer, as they are most reliable and allow for the lowest latency. 
          Set the sampling rate to 48 kHz and the buffer size to 128 samples. 
          Going below 128 is not recommended, because this may result in underruns and negatively impact audio quality.
        </p>
        <Row className='justify-content-md-center'>
          <Col className='text-center'>
            <Image className='pb-4' src={studioOneProAudioSetup} alt='Audio Setup' fluid />
          </Col>
        </Row>
        
        <p>
          <strong>Step 2</strong>
        </p>
        <p>
          Open external devices in options and add a new keyboard. 
          Select loopMIDI Port or IAC Driver Bus to receive MIDI data from.
        </p>
        <Row className='justify-content-md-center'>
        <Col className='text-center'>
          <Image className='pb-4' src={studioOneProExternalDevices} alt='External Devices' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 3</strong>
        </p>
        <p>
          Add a mono audio track and open it in the console. 
          Select the input from your audio interface with your mic or instrument connected as the audio input source and 
          insert Transkr V2 into the effects chain. Make sure to activate monitoring.
        </p>
        <Row className='justify-content-md-center'>
        <Col className='text-center'>
          <Image className='pb-4' src={studioOneProAudioTrack} alt='Audio Track' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 4</strong>
        </p>
        <p>
          Add a virtual instrument and select the virtual keyboard as direct input. 
          The instrument will receive MIDI data from Transkr V2 via the MIDI port.
        </p>
        <Row className='justify-content-md-center'>
        <Col className='text-center'>
          <Image className='pb-4' src={studioOneProInstrument} alt='Virtual Instrument' fluid />
          </Col>
        </Row>

        <p>
          <strong>Step 5</strong>
        </p>
        <p>
          Open Transkr V2 and
        </p>
        <ul>
          <li>
            set the line input to the channel associated with your audio input (only relevant for stereo input)
          </li>
          <ul>
            <li>
              Make sure the VU meter reacts to your input and reaches 100% without saturating.
            </li>
            <li>
              Use the gain knob on your audio interface and/or the plug-in to adjust the input level.
            </li>
            <li>
              Use the &quot;pass thru&quot; button to verify that your input is free of clicks and pops.
            </li>
          </ul>
          <li>
            set the MIDI output to the MIDI port that shall receive MIDI data from the plug-in
          </li>
          <li>
            select one of the defaults from the preset list that best represents your instrument
          </li>
        </ul>
        <Row className='justify-content-md-center'>
        <Col className='text-center'>
          <Image className='pb-4' src={studioOneProTranskrV2} alt='Transkr V2' fluid />
          </Col>
        </Row>

        <p>
          You should now be all set to start using Transkr V2 in Studio One Pro.
        </p>
      </Container>
    </section>
  )
}

export default StudioOneProUserGuide
