import React, { useEffect } from 'react'
import { Col, Container } from 'react-bootstrap'
import './style.scss'

const userGuideView = () => {
	window.gtag_sendEvent('user_guide')
}

const UserGuide = () => {
  useEffect(() => userGuideView(), [])

  const today = new Date('February 2, 2025')
  
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  return (
    <div>
    <section id='user-guide'>
      <Container className='user-guide-text'>
        <h2>User Guide</h2>
        <p>Last updated: {today.toLocaleDateString("en-SE", options)}</p>

        <ul className='toc'>
          <li>
            <a href="#installation">Installation</a>
            <ul>
              <li><a href='#macos'>macOS</a></li>
              <li><a href='#windows'>Windows</a></li>
            </ul>
          </li>
          <li>
            <a href="#quick-setup">Quick Setup</a>
            <ul>
              <li><a href='#ableton-live'>Ableton Live</a></li>
              <li><a href='#other-daws'>Bitwig Studio</a></li>
              <li><a href='#other-daws'>Cubase Pro</a></li>
              <li><a href='#other-daws'>FL Studio</a></li>
              <li><a href='#other-daws'>GarageBand</a></li>
              <li><a href='#other-daws'>Logic Pro</a></li>
              <li><a href='#other-daws'>Pro Tools</a></li>
              <li><a href='#other-daws'>REAPER</a></li>
              <li><a href='#other-daws'>Studio One Pro</a></li>
            </ul>
          </li>
          <li>
            <a href="#plug-ins">Plug-Ins</a>
            <ul>
              <li><a href='#plug-ins'>Pitch Perfekt</a></li>
              <li><a href='#plug-ins'>Transkr V2</a></li>
            </ul>
          </li>
        </ul>

        <h3 id='installation'>Installation</h3>

        <h4 id='macos'>macOS</h4>
        <ol>
          <li>Download the archive (.zip)</li>
          <li>Open the ZIP file</li>
          <li>Run the installer (.pkg)</li>
        </ol>
        <div className='important-box'>
          <p>
            For the plug-in to send MIDI data to your DAW, you need to enable the IAC Driver and set up at least one MIDI port. 
            You can run several instances of the plug-in simultaneously. 
            We recommend using a separate port for each instance. 
            Please refer to Apple's <a href='https://support.apple.com/guide/audio-midi-setup/welcome' target='_blank' rel='noreferrer'>Audio MIDI Setup User Guide</a> for more details. 
            You can find the exact sequence of steps under <a href='https://support.apple.com/guide/audio-midi-setup/transfer-midi-information-between-apps-ams1013' target='_blank' rel='noreferrer'>Transfer MIDI information between apps in Audio MIDI Setup on Mac</a>.
          </p>
          <p>
            If you use Logic Pro, you can alternatively resort to its built-in virtual MIDI device, Logic Pro Virtual In, to receive MIDI messages. 
            You will find more information in the <a href='https://support.apple.com/guide/logicpro/welcome' target='_blank' rel='noreferrer'>Logic Pro User Guide</a> under <a href='https://support.apple.com/en-il/guide/logicpro/lgcp9f309701' target='_blank' rel='noreferrer'>Receive MIDI messages from another music app in Logic Pro for Mac</a>. 
            {' '}
            <a href='https://support.apple.com/guide/audio-midi-setup/test-your-midi-connection-ams668e66f1d' target='_blank' rel='noreferrer'>Test your MIDI connection</a>, should you fail to receive any MIDI data.
          </p>
          <p>
            The plug-in is an audio effects plug-in and should be treated as such. 
            If, after the installation, you don&apos;t see it listed in your DAW, we advise you to have a look at Apple&apos;s <a href='https://support.apple.com/en-us/109315' target='_blank' rel='noreferrer'>recommendations</a>.
          </p>
          <p>
            Should you see a warning dialog when openening the standalone app, 
            have a look <a href='https://support.apple.com/guide/mac-help/open-a-mac-app-from-an-unidentified-developer-mh40616' target='_blank' rel='noreferrer'>here</a> for help.
          </p>
        </div>

        <h4 id='windows'>Windows</h4>
        <ol>
          <li>Download the archive (.zip)</li>
          <li>Open the ZIP file</li>
          <li>Run the installer (.exe)</li>
        </ol>
        <div className='important-box'>
          <p>
            For the plug-in to send MIDI data to your DAW, you need to have a virtual loopback device with a MIDI port installed, 
            see <a href='https://www.tobias-erichsen.de/software/loopmidi.html' target='_blank' rel='noreferrer'>loopMIDI</a>. 
            You can also use the built-in Microsoft GS Wavetable Synth, although we don&apos;t endorse it beyond testing. 
            You can run several instances of the plug-in simultaneously. 
            We recommend using a separate port for each instance.
          </p>
          <p>
            The plug-in is an audio effects plug-in and should be treated as such. 
            If, after the installation, you don&apos;t see it listed in your DAW, 
            try scanning the folder &quot;<tt>%COMMONPROGRAMFILES%\VST3</tt>&quot; 
            &mdash; typically &quot;<tt>C:\Program Files\Common Files\VST3</tt>&quot; &mdash; 
            in your DAW&apos;s plug-in manager. 
            In some rare cases, it might be necessary to explicitly set the plug-in type to Effect.
          </p>
          <p>
            The installer comes with a <a href='https://learn.microsoft.com/en-us/cpp/windows/latest-supported-vc-redist' target='_blank' rel='noreferrer'>Microsoft Visual C++ Redistributable</a> package required to launch the plug-in.
            You can also download the latest supported x64 version from <a href='https://aka.ms/vs/17/release/vc_redist.x64.exe' download>here</a>.
          </p>
          <p>
            Should Windows Security prevent the standalone app from opening, 
            have a look <a href='https://support.microsoft.com/en-us/windows/add-an-exclusion-to-windows-security-811816c0-4dfd-af4a-47e4-c301afe13b26' target='_blank' rel='noreferrer'>here</a> for help.
          </p>
        </div>

        <h3 id='quick-setup'>Quick Setup</h3>
        <p>
          If you don&apos;t intend to use the plug-in for creative live performances with a real-time constraint, 
          but simply wish to convert your vocal recordings to MIDI, 
          we suggest you use the drag-and-drop file-conversion feature and the standalone app instead. 
          It will save you time.
        </p>
        <p>
          Follow these 5 steps to quickly set up the plug-in in your DAW of choice:
        </p>
        <ol>
          <li>Connect and set up your audio interface</li>
          <li>Make sure a virtual MIDI device is available and enabled (see above)</li>
          <li>Add the plug-in to an audio track&apos;s effects rack with audio from your interface as input</li>
          <li>Select the virtual MIDI device&apos;s port and channel in the plug-in</li>
          <li>Add a virtual instrument to receive data from the virtual MIDI device&apos;s port</li>
        </ol>
        <p>
          For more details, continue reading.
        </p>

        <h4 id='ableton-live'>Ableton Live</h4>
        <p>
          Ableton Live <a href='https://help.ableton.com/hc/en-us/articles/5937501570460' target='_blank' rel='noreferrer'>supports</a> both AU and VST3 plug-in formats. 
          You can find more information on how to <a href='https://help.ableton.com/hc/en-us/articles/209774225' target='_blank' rel='noreferrer'>set up a virtual MIDI bus</a> and how to <a href='https://help.ableton.com/hc/en-us/articles/209774205' target='_blank' rel='noreferrer'>configure MIDI ports</a> on the respective pages. 
          Also have a look at other DAW examples below to learn how to set up Transkr V2.
        </p>

        <h4 id='other-daws'>Other DAWs</h4>
        <Col lg={3} sm={6} md={6} className='other-daws'>
          <p>
            <a className='daw-link' href='/guide/bitwig-studio'>Bitwig Studio</a>
            <a className='daw-link' href='/guide/cubase-pro'>Cubase Pro</a>
            <a className='daw-link' href='/guide/fl-studio'>FL Studio</a>
            <a className='daw-link' href='/guide/garageband'>GarageBand</a>
            <a className='daw-link' href='/guide/logic-pro'>Logic Pro</a>
            <a className='daw-link' href='/guide/pro-tools'>Pro Tools</a>
            <a className='daw-link' href='/guide/reaper'>REAPER</a>
            <a className='daw-link' href='/guide/studio-one-pro'>Studio One Pro</a>
          </p>
        </Col>

        <h3 id='plug-ins'>Plug-Ins</h3>
        <Col lg={3} sm={6} md={6} className='other-daws'>
          <p>
            <a className='daw-link' href='/guide/pitch-perfekt'>Pitch Perfekt</a>
            <a className='daw-link' href='/guide/transkr-v2'>Transkr V2</a>
          </p>
        </Col>
      </Container>
    </section>
    </div>
  )
}

export default UserGuide
